<template>
<loader v-bind="{ loading }">
    <columns>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="barcode" class="fa-2x has-text-grey" />
                <p>
                   Prieces of Equipment
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                    <p class="is-size-3">{{ equipment_classification_overview.pieces_of_equipment | abbreviateCount }}</p>
                    <span class="tag is-success">+ 53%</span>
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="barcode" class="fa-2x has-text-grey" />
                <p>
                   Faulty Equipment
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                    <p class="is-size-3">{{ equipment_classification_overview.faulty_equipment | abbreviateCount }}</p>
                    <span class="tag is-success">+ 53%</span>
                </div>
            </div>
        </column>
        <column>
            <div class="box is-fullheight is-round p-5">
                <icon icon="barcode" class="fa-2x has-text-grey" />
                <p>
                   Prieces of Equipment
                </p>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                    <p class="is-size-3">{{ equipment_classification_overview.operational_equipment | abbreviateCount }}</p>
                    <span class="tag is-success">+ 53%</span>
                </div>
            </div>
        </column>
    </columns>
</loader>
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    data: () => ({
        loading: true
    }),

    async beforeCreate() {
        await this.$store.dispatch('equipmentClassification/loadOverview', this.$route.params.equipmentClassification)
        this.loading = false
    },

    computed: {
        ...mapGetters('equipmentClassification', [
            'equipment_classification_overview'
        ])
    }

}
</script>